@import './reset.less';
@import './mixin.less';
@import './grid.less';
@import './common.less';
@import '../fonts/iconfont.css';

#root {
  color: #212121;
  background-color: #f5f5f5;
  .header-container,
  .footer-container {
    padding-left: 10%;
    padding-right: 10%;
  }
  .block-container {
    margin-bottom: 20px;
  }
  .white-container {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}

@media (max-width: @middle-width) {
  #root {
    .header-container,
    .footer-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.paginate-wrapper {
  text-align: center;
  margin-top: 30px;
  li {
    display: inline-block;
    a {
      display: block;
      text-align: center;
      margin: 0 4px;
      min-width: 28px;
      line-height: 28px;
      font-size: 14px;
      color: #4c4f73;
      border: 1px solid rgba(76, 79, 115, 0.4);
      border-radius: 2px;
    }
  }
  .selected {
    a {
      background: #4c4f73;
      color: @white;
    }
  }
}

.page-block-container {
  position: relative;
  padding: 100px 0 100px 0;
  .pb-title {
    text-align: center;
    font-size: 32px;
    color: #333333;
  }
  .pb-subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 100px;
    line-height: 24px;
    font-size: 15px;
    color: #666666;
  }
  .content-profile {
    .text {
      margin-bottom: 20px;
      line-height: 24px;
      font-size: 15px;
      color: @font-color3;
    }
  }
}
.page-list-container {
  color: @gray-ccc;
  .page-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 40px;
    .iconfont {
      font-size: 40px;
      color: #3f6aff;
    }
    .title {
      margin: 20px 0 30px 0;
      font-size: 22px;
    }
    .sectitle {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .subtitle {
      text-align: center;
      line-height: 24px;
      min-height: 48px;
      font-size: 15px;
      color: @gray-ccc;
    }
    .mobile-cla {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }
  .page-list-item-online {
    align-items: flex-start;
    .title-image-online {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: 20px;
      .title {
        margin: 0 0 0 16px;
      }
    }
    .subtitle {
      text-align: left;
    }
  }
}
@media (max-width: @middle-width) {
  #root {
    .white-container {
      padding-top: 60px;
      padding-bottom: 20px;
    }
  }
  .page-block-container {
    padding: 60px 0;
    .pb-title {
      font-size: 26px;
    }
    .pb-subtitle {
      margin-bottom: 60px;
    }
    .page-list-item {
      .image {
        max-width: 50px;
      }
      .title {
        font-size: 16px;
      }
      .subtitle {
        min-height: 50px;
        font-size: 14px;
      }
    }
    .page-list-item-big-image {
      .image {
        max-width: none;
      }
    }
    .full-line-item {
      .page-list-item {
        margin-bottom: 40px !important;
      }
    }
    .mobile-list-item {
      align-items: flex-start;
      .mobile-cla {
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 20px;
      }
      .title {
        margin: 0 0 0 16px;
      }
      .subtitle {
        text-align: left;
      }
    }
  }
}
