@import '~@/asset/styles/variable.less';
@import '~@/asset/styles/mixin.less';

.footer-container {
  background-color: #1c1e34;
  color: #fafbff;
  flex-direction: column;
  padding-bottom: 30px;
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
  }
  .friend-links {
    font-size: 14px;
    color: @white;
    .link-tit {
      display: inline-block;
      margin-right: 20px;
    }
    > a {
      display: inline-block;
      margin-right: 20px;
      font-size: 14px;
      color: @white;
    }
  }
  .footer-info {
    line-height: 24px;
    font-size: 13px;
    .cusicon-tel {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background-image: url('../../../asset/images/common/button_tell_icon.png');
      background-size: cover;
    }
    .tel {
      margin-bottom: 10px;
      font-size: 18px;
    }
    .work-time {
      font-size: 14px;
    }
    .address {
      margin-bottom: 20px;
      font-size: 16px;
    }
    .copyright,
    .record {
      font-size: 13px;
    }
  }
  .friend-links {
    display: block;
  }
  .footer-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .footer-nav-item {
      margin: 0 10px;
    }
    .trade-name {
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: bold;
    }
    .nav-trade-link {
      display: inline-block;
      margin-top: 14px;
      font-size: 13px;
      color: #fafbff;
    }
    .nav-trade-overview {
      font-size: 12px;
    }
  }
}

@media (max-width: @middle-width) {
  .footer-container {
    .footer-wrapper {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
