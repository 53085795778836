@import './variable.less';

.bg-image(@path; @w: 100%; @h: auto; @ext: png) {
  background-image: url('@{path}.@{ext}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: @w @h;
  @media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
    background-image: url('@{path}@2x.@{ext}');
  }
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    background-image: url('@{path}@2x.@{ext}');
  }
}

.bg-image-attr(@size: contain, @position: center) {
  background-size: @size;
  background-position: @position;
  background-repeat: no-repeat;
}

.bg-image-other(path) {
  background-image: url('@{path}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.col-grid-loop(@num; @type) when(@num <= 12) {
  .col-grid-loop(@num + 1; @type);
  .col-@{type}-@{num} {
    float: left;
    width: unit(@screen-width / (12 / @num), ~'%');
  }
}
/* 百分比的col-per-x的模式 */
.col-percentage-loop(@num) when(@num <= 12) {
  .col-percentage-loop(@num + 1);
  .col-num-@{num} {
    float: left;
    width: unit(@screen-width / @num, ~'%');
  }
}

.flex-center(@direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: @direction;
}
