@keyframes homeToggleDownPageIcon {
  0% {
    transform: translate(0, 0);
    opacity: 0.4;
  }
  50% {
    transform: translate(0, 10px);
    opacity: 1;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.8);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0.4;
    // font-size: 30px;
    color: @white;
  }
}
