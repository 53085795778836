@import './mixin.less';
/* 栅格 start */
.container-fluid {
  position: relative;
  padding-right: @gap-width;
  padding-left: @gap-width;
  margin-right: auto;
  margin-left: auto;
}
.container {
  position: relative;
  padding-right: @gap-width;
  padding-left: @gap-width;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -(@gap-width);
  margin-left: -(@gap-width);
  &:after {
    display: table;
    content: ' ';
    clear: both;
  }
}
:after,
:before {
  box-sizing: border-box;
}
/*.no-gap,
.no-gap .row,*/
.no-gap [class*='col-'] {
  padding: 0;
  margin: 0;
  // margin-right: -15px;
  // margin-left: -15px;
}
[class*='col-lg-'],
[class*='col-md-'],
[class*='col-sm-'],
[class*='col-xs-'],
[class*='col-num-'] {
  position: relative;
  min-height: 1px;
  padding-right: @gap-width;
  padding-left: @gap-width;
}
/* 百分比处理 */
.col-percentage-loop(1);

/* 栅格处理 */
/** screen >= 768 **/
@media (min-width: @small-width) and (max-width: @middle-width) {
  .container {
    width: @small-width;
  }
  .hidden-sm {
    display: none !important;
  }
  .col-grid-loop(1; sm);
}
/** screen 768 >= 992 **/
@media (min-width: @middle-width) and (max-width: @large-width) {
  .container {
    width: @middle-width;
  }
  .hidden-md {
    display: none !important;
  }
  .col-grid-loop(1; md);
}

/* screen 992 >= 1200 */
@media (min-width: @large-width) {
  .container {
    width: @large-width;
  }
  .hidden-lg {
    display: none !important;
  }
  .col-grid-loop(1; lg);
}

/* screen < 768 */
@media (max-width: @small-width) {
  .hidden-xs {
    display: none !important;
  }
  .col-grid-loop(1; xs);
}

/* 栅格 end */
