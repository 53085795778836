@screen-width: 100;
@large-width: 1200px;
@middle-width: 992px;
@small-width: 768px;
@gap-width: 15px;

@blue: #00c1de;
@gray: #93999f;
@gray-ddd: #ddd;
@gray-eee: #eee;
@gray-ccc: #333;
@gray-fa: #fafafa;
@black: #000;
@white: #fff;
@white2: #f5f5f5;
@red: red;

@font-color: #232b36;
@font-color2: #1c1f34;
@font-color3: #737482;
@font-color4: #babbc2;
@footer-bg: #1f2630;
@bg-color: #3a78e8;
@border: #f1f1f1;
