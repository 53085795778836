@import './variable.less';

.fl {
  float: left;
}

.fr {
  float: right;
}
.clearfix {
  &:after {
    display: table;
    content: ' ';
    clear: both;
  }
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt50 {
  margin-top: 50px;
}
.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb50 {
  margin-bottom: 50px;
}

.bgfff {
  background: #fff;
}

.no-pad {
  padding: 0 !important;
}

.hide {
  overflow: hidden;
}

.cen {
  text-align: center;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.rela {
  position: relative;
}

.w100 {
  width: 100%;
}

a {
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

a:hover {
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

.color-white {
  color: @white;
}

.color-black {
  color: @font-color;
}

.flex-cen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-left {
  display: flex;
  justify-content: left;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
